<template>
  <v-data-table
    class="tp-table-scroll__with-title tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="orderPolicyStatusRequest.value === 'loading-getOrderPolicies'"
    loading-text="Đang tải dữ liệu"
    :items="orderPolicies"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.last_updated_by_user`]="{ item }">
      {{ item.last_updated_by_user ? item.last_updated_by_user.name : "N/A" }}
    </template>
  </v-data-table>
</template>

<script>
import { HEADERS_ORDER_POLICY_TABLE } from "@/modules/Policy/constants";

export default {
  data() {
    return {
      headers: HEADERS_ORDER_POLICY_TABLE
    };
  },

  computed: {
    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    },

    orderPolicies() {
      return this.$store.getters["ORDER_POLICY/orderPolicies"];
    },

    orderPolicyStatusRequest() {
      return this.$store.getters["ORDER_POLICY/statusRequest"];
    }
  },

  methods: {
    async viewDetail(item) {
      await this.$store.dispatch("ORDER_POLICY/getOrderPolicyById", item.id);

      await this.$router.push({
        name: "policy_order-policies-detail",
        params: {
          orderPolicyId: this.orderPolicy.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
