<template>
  <div class="pl-5">
    <div class="font-weight-bold text-h5 mb-4">
      Chính sách mua/đặt hàng từ xa
    </div>
    <!-- Start: Top actions -->
    <top-action
      :current-page="currentPage"
      :selected-brands="selectedBrands"
      :selected-show-filter-id="itemPerPage"
      :search-key="searchKey"
      @updateCurrentPage="updateCurrentPage($event)"
      @updateItemPerPage="updateItemPerPage($event)"
      @updateSearchKey="updateSearchKey($event)"
      @updateSelectedBrands="selectedBrands = $event"
    ></top-action>
    <!-- End: Top actions -->
    <!-- Start: Data table -->
    <div class="mr-5">
      <data-table-items></data-table-items>
    </div>
    <!-- End: Data table -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    TopAction
  },
  data() {
    return {
      currentPage: 1,
      itemPerPage: 50,
      searchKey: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getOrderPolicies(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getOrderPolicies(route.query);
  },
  methods: {
    async getOrderPolicies(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;

        // Get products
        await this.$store.dispatch("ORDER_POLICY/getOrderPolicies", {
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    async pushRouter() {
      await this.$router.push({
        name: "policy_order-policies",
        query: {
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedBrands = [];
      // Push router query
      this.pushRouter();
    },

    async updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedBrands = [];
      // Push router query
      await this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
